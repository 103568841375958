import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { UserManager } from '@core/managers/UserManager';

@Component({
  selector: 'app-emergency-contact',
  standalone: true,
  providers: [provideNativeDateAdapter()],
  imports: [
    CommonModule,
    FormsModule,ReactiveFormsModule, MatFormFieldModule,MatInputModule,MatButtonModule,
    MatDatepickerModule,
    MatButtonToggleModule,
  ],
  templateUrl: './emergency-contact.component.html',
  styleUrls: ['./emergency-contact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmergencyContactFormComponent {
 
  
  

  form : FormGroup = new FormGroup({
    email : new FormControl(null,[Validators.email]),
    contact_name : new FormControl(),
    relationship : new FormControl(),
    phone : new FormControl(null),
  });

  @Input()
  block : boolean = false;

  constructor(private changes : ChangeDetectorRef){}
  
  reaload(){
    this.changes.detectChanges();
  }

 
  reset(body : any) {
    if(!body) {
      this.form.reset();
      return;
    }
    this.form.setValue(body);
  }

}
