import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-address',
  standalone: true,
  providers: [provideNativeDateAdapter()],
  imports: [
    CommonModule,
    FormsModule,ReactiveFormsModule, MatFormFieldModule,MatInputModule,MatButtonModule,
    MatDatepickerModule,
    MatButtonToggleModule,
  ],
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressFormComponent { 

  form : FormGroup = new FormGroup({
    street_address : new FormControl(),
    country : new FormControl(),
    state : new FormControl(),
    city : new FormControl(),
    zipcode : new FormControl()
  });

  @Input() block : boolean = false;

  constructor(private changes : ChangeDetectorRef){}
    
  reaload(){
    this.changes.detectChanges();
  }


  reset(body : any) {
    if(!body) {
      this.form.reset();
      return;
    }
    this.form.setValue(body);
  }
  
}
