<form method="post" [formGroup]="form">
    
  <div style="display: flex; flex-wrap: wrap; justify-content: space-between; gap: 1em;">
    <mat-form-field [floatLabel]="'always'" style="flex-grow:1;">
      <mat-label>Contact name</mat-label>
      <input [readonly]="block" formControlName="contact_name" type="text" matInput  />
    </mat-form-field>
    <mat-form-field [floatLabel]="'always'" style="flex-grow:1;">
      <mat-label>Relationship</mat-label>
      <input [readonly]="block" formControlName="relationship" type="text" matInput  />
    </mat-form-field>
  </div>
    <div style="display: flex; flex-wrap: wrap; justify-content: space-between; gap: 1em;">
      <mat-form-field [floatLabel]="'always'"  style="flex-grow:1;">
        <mat-label>Email</mat-label>
        <input [readonly]="block" formControlName="email" type="email" matInput  />
      </mat-form-field>
      <mat-form-field [floatLabel]="'always'" style="flex-grow:1;">
        <mat-label>Phone</mat-label>
        <input [readonly]="block" formControlName="phone" type="tel" matInput  />
      </mat-form-field>
    </div>
</form>