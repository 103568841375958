import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { AddressFormComponent } from "./address/address.component";
import { EmergencyContactFormComponent } from "./emergency-contact/emergency-contact.component";
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { UserManager } from '@core/managers/UserManager';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SnackbarService } from '@services/snackbar.service';
import { UserIconService } from '@services/userIconService';
import { RouterModule } from '@angular/router';
import {MatTabsModule} from '@angular/material/tabs';
import { SEOService } from '@services/seo.service';
import { Iuser } from '@core/interfaces/user.interface';
import { Utils } from '@utils/Utils';
import moment from 'moment';
import { GLOBAL_FORMAT } from '@data/const/format-date';
import { RegisterComponent } from 'app/layouts/header/register/register.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [
    CommonModule,
    MatDividerModule,
    AddressFormComponent,
    EmergencyContactFormComponent,
    MatIconModule,
    MatButtonModule,
    RouterModule,
    MatProgressSpinnerModule,
    MatTabsModule
],
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileComponent implements OnInit{



  profileImage ?: string; 
  fileUserIcon ?: File;
  loading : boolean = false;
  loadingAdress : boolean = false;

  loadingEmergency : boolean = false;
  emergencyVals : any;
  addressVals : any;
  user : Iuser;
  Utils = Utils
  @ViewChild (EmergencyContactFormComponent) emergency !: EmergencyContactFormComponent;
  @ViewChild (AddressFormComponent) address !: AddressFormComponent;

  constructor(protected userManager : UserManager, 
    private dialog : MatDialog,
    private seo : SEOService, private snackbar : SnackbarService, private changes : ChangeDetectorRef, private userIconService : UserIconService ){
    
    this.seo.setTitle('Dashboard - Profile')
    this.user = this.userManager.getUser()!;
  
    const profileImg = this.userManager.getProfileImage(true);
    if(profileImg){
      this.profileImage = profileImg;
    }
  }

  ngOnInit(): void {
    this.userManager.getInformation({
      next : (response) => {
        
        this.addressVals = response.address;
        this.emergencyVals = response.emergency_contact;
        
        if(this.emergencyVals){
          this.emergency.form.patchValue(this.emergencyVals);
          this.emergency.reaload();
        }
        
        if(this.addressVals) {
          this.address.form.patchValue(this.addressVals);
          this.address.reaload();
        }
      }
    })
  }


  onCancel(){
    if(this.emergencyVals){
      this.emergency.form.patchValue(this.emergencyVals);
      this.emergency.reaload();
    }
    
    if(this.addressVals) {
      this.address.form.patchValue(this.addressVals);
      this.address.reaload();
    }
  }

  logout(event: any) {
    if(event){
      event.stopPropagation();
      event.preventDefault();
    }
    this.userManager.getAuth().logout();
    window.location.reload();
  }
    


  base64ToBlob(base64Image: string): Blob {
    return this.userManager.fileManager.base64ToBlob(base64Image);
  }

  notLandlord(){
    return true;
  }


  onSave( ) {
   
      if(this.loadingEmergency) return;

      const body = this.emergency.form.getRawValue();
      const body2 = this.address.form.getRawValue();


      this.loadingEmergency = true;

      this.userManager.saveUserInformation(body,body2,{
        next : (response) => {
          if(response == 'OK'){
            this.snackbar.text('Emergency contact and Address saved','ok');
            this.emergencyVals = body;
            this.emergency.form.setValue(body);

            this.addressVals = body2;
            this.address.form.setValue(body2);
          }
        },
        finalize: () => {
          this.loadingEmergency = false;
          this.changes.detectChanges();
        }
      })

      
  }

  async changeProfileImage (event: any){
    if(this.loading) return;

    let files = event.target.files;
    if(!files) return;

    const file : File  = files.item(0)!;
    this.profileImage = URL.createObjectURL(file);
    this.loading = true;

    await this.userManager.fileManager.removeMetadata(file).then(async (v : File)=>{
      this.fileUserIcon = new File([v],this.userManager.fileManager.removeBlob(this.profileImage!,v.name));;
      const fils : any = await this.userManager.fileManager.processImages([this.fileUserIcon]); 
      this.fileUserIcon = fils[0];
    })

    this.userManager.changeFiles({image: this.fileUserIcon},{
      next : (response)=>{
        this.snackbar.text('Images uploaded!','ok');
        this.userManager.profileImage();
        this.userIconService.update();
        
      },
      error : (response) => {
        this.snackbar.text('Image could not be loaded','error');
        delete this.profileImage;
        delete this.fileUserIcon;
      },
      finalize: ()=>{
        this.loading = false;
        this.changes.detectChanges();
      }
    })

  }

  formatDate(date:string|null) {
    if(!date) return ''
    return moment(date,GLOBAL_FORMAT).format('DD/MM/YYYY');
  }


  deleteProfileImage(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    
    this.userManager.deleteProfileImage({
      next : (response)=>{
        if(response == 'OK'){
          this.snackbar.text('Image deleted!','ok');
          this.profileImage = undefined;

          this.userManager.removeProfileImage();
          this.userIconService.update();
          this.changes.detectChanges();
        }
      },
      error : (response)=>{
        this.snackbar.text('Images could not be deleted','error');
      }
    });
  }


  completeProfile(event : any){
    if(event){
      event.stopPropagation();
      event.preventDefault();
    }
 
    const dialogRef = this.dialog.open(RegisterComponent, {
      data: {start_complete : true},
      width: '80%',
      maxWidth: 'unset',
      height: 'fit-content',
      maxHeight: 'unset',
      backdropClass: 'cdk-modal-register',
      minWidth: '95vw'
    });

    const DIALOG_REF = dialogRef.afterClosed().subscribe(result => {
      DIALOG_REF.unsubscribe();
      if(result && result.completed_login){
        this.user = this.userManager.getUser()!;
        this.changes.detectChanges();
      }
    });
  }


}
