<div class="profile-container">
    <!-- Header Profile Section -->
    <div class="profile-header">
      <div class="header-content">
        <div class="profile-image-container">
          <div class="profile-image" [class.empty]="!profileImage">

            @if(profileImage){
                <a [href]="profileImage" target="_blank" style="width: 100%; height: 100%;">
                    <img [src]="profileImage" alt="Profile" />
                </a>
            }@else {
                <svg  class="default-avatar" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2M12 3a4 4 0 1 0 0 8 4 4 0 0 0 0-8z"/>
                </svg>
            }
          </div>
          @if(profileImage){
            <label class="image-upload-button close" (click)="deleteProfileImage($event)">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
            </label>
          }
          <label class="image-upload-button" for="photo-upload">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"/>
              <circle cx="12" cy="13" r="4"/>
            </svg>
            <input
              id="photo-upload"
              type="file"
              accept="image/*"
              hidden
              (change)="changeProfileImage($event);"
            />
          </label>
        </div>
        
        <div class="profile-info">
          <h1>{{user.name}} {{user.surname}}</h1>
          <div class="info-tags">
            <div class="info-tag">
              <mat-icon>{{userManager.getGender(user)}}</mat-icon>
              <span>{{Utils.capitalize(userManager.getGender(user))}}</span>
            </div>
            @if(user.birthdate){
              <div class="info-tag">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <rect x="3" y="4" width="18" height="18" rx="2" ry="2"/>
                  <line x1="16" y1="2" x2="16" y2="6"/>
                  <line x1="8" y1="2" x2="8" y2="6"/>
                  <line x1="3" y1="10" x2="21" y2="10"/>
                </svg>
                <span>{{formatDate(user.birthdate)}}</span>
              </div>
            }
            @if(userManager.getCountryName(user.nationality)){
              <div class="info-tag">
                  <img width="20" height="20" style="margin-right: 0.5em;" [src]="userManager.getCountryAsset(user.nationality)" [title]="userManager.getCountryName(user.nationality)">
                <span>{{userManager.getCountryName(user.nationality)}}</span>
              </div>
            }

            @if(userManager.notComplete()){
              <div class="button-container">
                <button (click)="completeProfile($event)" class="profile-button" style="font-family: Montserrat;">
                    <span class="icon">✨</span>Complete Your Profile
                </button>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  
    <div class="cards-container">
      <!-- Personal Information Card -->
      <div class="card">
        <div class="card-header">
          <h2>Personal Information</h2>
        </div>
        <div class="card-content">
          <div class="info-field">
            <label>Email</label>
            <div class="field-value with-icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"/>
                <polyline points="22,6 12,13 2,6"/>
              </svg>
              <span>{{user.email}}</span>
            </div>
          </div>
          <div class="info-field">
            <label>Phone number</label>
            <div class="field-value with-icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"/>
              </svg>
              <span>{{userManager.getPhone(user)}}</span>
            </div>
          </div>
        </div>
      </div>
  
      <div  class="card">
        <div class="card-header">
            <h2>Address</h2>
        </div>
        <div class="card-content">
            <app-address></app-address>
        </div>
      </div>
      <div  class="card">
        <div class="card-header">
            <h2>Emergency contact</h2>
        </div>
        <div class="card-content">
            <app-emergency-contact ></app-emergency-contact>
        </div>
      </div>

        <div class="action-buttons">
          <button [disabled]="loadingAdress || loadingEmergency" type="button" style="font-family: Montserrat; cursor: pointer;" class="btn-cancel" (click)="onCancel()">Cancelar</button>
          <button [disabled]="loadingAdress || loadingEmergency"  type="submit" style="font-family: Montserrat; cursor: pointer;" class="btn-save" (click)="onSave()">Guardar Cambios</button>
          <button type="button" class="logout" (click)="logout($event)">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
              <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
              <polyline points="16 17 21 12 16 7"></polyline>
          </svg>
            <span>Logout</span>  
          </button>  
        </div>
    </div>
  
    
</div>