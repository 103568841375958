<form method="post" [formGroup]="form">
    
    <mat-form-field [floatLabel]="'always'" style="width: 100%;">
      <mat-label>Street address</mat-label>
      <input formControlName="street_address" [readonly]="block" type="text" matInput  />
    </mat-form-field>
    <div style="display: flex; flex-wrap: wrap; justify-content: space-between; gap: 1em;">
      <mat-form-field [floatLabel]="'always'" style="flex-grow:1;">
        <mat-label>Country</mat-label>
        <input formControlName="country" [readonly]="block" type="text" matInput  />
      </mat-form-field>
      <mat-form-field [floatLabel]="'always'" style="flex-grow:1;">
        <mat-label>State</mat-label>
        <input formControlName="state" [readonly]="block" type="text" matInput  />
      </mat-form-field>
      <mat-form-field [floatLabel]="'always'" style="flex-grow:1;">
        <mat-label>City</mat-label>
        <input formControlName="city" [readonly]="block" type="text" matInput  />
      </mat-form-field>
      <mat-form-field [floatLabel]="'always'" style="flex-grow:1;">
        <mat-label>Zip code</mat-label>
        <input formControlName="zipcode" [readonly]="block" type="text" matInput  />
      </mat-form-field>
    </div>
  </form>